<script setup lang="ts">

import { ref } from 'vue'
import ToggleElement from '@/Components/the-accordion/Components/ToggleElement.vue'
import TheAccordionArrows from '@/Components/the-accordion/Components/TheAccordionArrows.vue'

const open = ref(false)
</script>

<template>
    <div
        class="card border-0 mb-4 pb-2"
    >
        <div
            class="tx-body font-weight-600 cursor-pointer"
            @click.prevent="open = ! open"
        >
            <span
                class="accordion-link cl-text py-0"
            >
                <slot name="question" />
                <TheAccordionArrows :open="open" />
            </span>
        </div>
        <ToggleElement :show="open" class="mt-2">
            <div class="tx-body cl-text text-content">
                <slot name="answer" />
            </div>
        </ToggleElement>
    </div>
</template>

<style scoped lang="scss">

</style>